// extracted by mini-css-extract-plugin
export var address = "OrderItem-module--address--6c4d9";
export var addressContainer = "OrderItem-module--addressContainer--4cae6";
export var addressDetailContainer = "OrderItem-module--addressDetailContainer--7c5e0";
export var addressMeta = "OrderItem-module--addressMeta--b4859";
export var bold = "OrderItem-module--bold--b012f";
export var detailsContainer = "OrderItem-module--detailsContainer--2fdb7";
export var grandTotal = "OrderItem-module--grandTotal--ec04b";
export var hide = "OrderItem-module--hide--0241e";
export var imageContainer = "OrderItem-module--imageContainer--cdf86";
export var itemContainer = "OrderItem-module--itemContainer--51680";
export var itemList = "OrderItem-module--itemList--7d82d";
export var itemName = "OrderItem-module--itemName--8717b";
export var itemQuantity = "OrderItem-module--itemQuantity--be983";
export var itemTotal = "OrderItem-module--itemTotal--4627a";
export var itemTotalMobile = "OrderItem-module--itemTotalMobile--50317";
export var lastUpdate = "OrderItem-module--lastUpdate--91d12";
export var mobileLabel = "OrderItem-module--mobileLabel--869c1";
export var od = "OrderItem-module--od--f838d";
export var orderDate = "OrderItem-module--orderDate--117f4";
export var orderHeader = "OrderItem-module--orderHeader--56952";
export var orderId = "OrderItem-module--orderId--a589c";
export var orderItemMeta = "OrderItem-module--orderItemMeta--88150";
export var orderMeta = "OrderItem-module--orderMeta--d6840";
export var orderTotalMeta = "OrderItem-module--orderTotalMeta--1483f";
export var paddingBottom = "OrderItem-module--paddingBottom--a5e6e";
export var root = "OrderItem-module--root--9e911";
export var rotate = "OrderItem-module--rotate--764c0";
export var show = "OrderItem-module--show--3c355";
export var st = "OrderItem-module--st--9453d";
export var status = "OrderItem-module--status--d134a";
export var toggleContainer = "OrderItem-module--toggleContainer--d5d83";
export var total = "OrderItem-module--total--3ff20";
export var transactionDetails = "OrderItem-module--transactionDetails--59346";
export var transactionalGrid = "OrderItem-module--transactionalGrid--7c5df";